import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(20.000000, 30.000000)">
        <path
          d="M16.9234 11.76V26.84C16.9234 29.9067 16.3501 32.4267 15.2034 34.4C14.0701 36.3867 12.3768 37.96 10.1234 39.12C7.88344 40.2933 5.1101 41.2067 1.80344 41.86L0.803438 37.04C2.9501 36.5467 4.7501 35.9333 6.20344 35.2C7.65677 34.4667 8.7501 33.4733 9.48344 32.22C10.2301 30.9533 10.6034 29.2933 10.6034 27.24V16.08H2.96344V11.76H16.9234ZM12.8234 0.639999C13.9168 0.639999 14.8101 0.979999 15.5034 1.66C16.1968 2.34 16.5434 3.18667 16.5434 4.2C16.5434 5.21333 16.1968 6.06667 15.5034 6.76C14.8101 7.44 13.9168 7.78 12.8234 7.78C11.7168 7.78 10.8101 7.44 10.1034 6.76C9.4101 6.06667 9.06344 5.21333 9.06344 4.2C9.06344 3.18667 9.4101 2.34 10.1034 1.66C10.8101 0.979999 11.7168 0.639999 12.8234 0.639999ZM44.8478 11.76L37.7678 33H30.3278L23.1678 11.76H29.9878L34.1278 28.16L38.4478 11.76H44.8478ZM47.9922 40.44V35.48H67.9922V40.44H47.9922Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
